const carouselData = [
  {
    id: 1,
    title: "Surya Kriya",
    location: "Location: Pune",
    date: "1st Feb - 2nd Feb 2025",
    time: "06:30 AM - 09:30 AM",
    registrationLink: "/surya-kriya",
    startDate: "2025-02-01",
    endDate: "2025-02-02"
  }
];

export default carouselData;