// src/CardCarousel.js
import React, { useEffect } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import carouselData from './carouselData';
import styles from './ProgramCarousel.module.scss';

function ProgramCarousel() {
  useEffect(() => {
    // Ensure the carousel-indicators exist after the page has loaded
    setTimeout(() => {
      const carouselIndicators = document.querySelector('.carousel-indicators');
      if (carouselIndicators) {
        carouselIndicators.classList.add('loaded');
      }
    }, 500); // Delay of 500ms to ensure the indicators have been rendered
  }, []);
  const currentDate = new Date();
  
  // Filter out programs whose endDate has passed
  const upcomingPrograms = carouselData.filter(program => {
    const programEndDate = new Date(program.endDate); // Convert the endDate to a Date object
    return currentDate <= programEndDate; // Only include courses where current date is before or equal to the end date
  });
 
  // Split data into groups of two for each carousel slide
  const groupedData = [];
  for (let i = 0; i < upcomingPrograms.length; i += 2) {
    groupedData.push(upcomingPrograms.slice(i, i + 2));
  }
  
  return (
    <Carousel interval={3000} controls={true} indicators={true} pause={'hover'} className={styles.main}>
      {groupedData.map((group, index) => (
        <Carousel.Item key={index}>
          <div className={`row ${styles.item}`}>
            {group.map((item) => (
              <div key={item.id} className={`col-12 col-sm-12 col-md-12 col-lg-6 card m-3 p-2 ${styles.card}`}>
                <div className={styles.cardContent}>
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text text-center">{item.location}</p>
                  <p className="card-text text-center">
                    {item.date}<br/>{item.time}
                  </p>
                  <div>
                    <Button variant="primary" href={item.registrationLink} className={styles.button}>
                      Register
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ProgramCarousel;