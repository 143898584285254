import styles from './Bhutashuddhi.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/bhut_shuddhi_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Bhutshuddhi = () => {
    return(
        <div className={styles.title}>
            <h1>Bhuta Shuddhi</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='bhutashuddhi' width='100%'></img>
                    <p>Bhuta Shuddhi means “purification of the elements,” and is a process of purifying the 5 elements within the human system. The system of yoga called bhuta shuddhi, meaning purification of the elements, is the fundamental process that underlies every other yogic practice. Simple yet powerful Bhuta Shuddhi processes are offered as a part of Isha Hatha Yoga programs, allowing one to cleanse the body and prepare for more powerful sadhanas.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Keeps the system in harmony and balance.<br/>
                    ♦ Prepares the system to handle powerful states of energy.<br/>
                    ♦ Enhances the capabilities of the physical body, mind and energy system.<br/>
                    ♦ Creates the basis to gain complete mastery over the human system.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Bhuta Shuddhi <hr/></h5>
                        <p><b>Location: </b>Manorama Hall, Railway lines, Old Employment chowk, Solapur - 413002</p>
                        <p><b>Investment/Fees:</b> 3200 /-(including the kit)</p>
                        <p><b>Date: 12th Jan, 2025</b>-</p>
                        <p><b>Time: 06:00 PM - 07:15 PM</b>-</p>
                        <Link to={"/bhuta-shuddhi/registration"}>
                            <Button >Register Now</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bhutshuddhi;