import React from 'react';
import './App.scss';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Angamardana from './components/Programs/Angamardana/Angamardana';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Bhutshuddhi from './components/Programs/BhutaShuddhi/Bhutashuddhi';
import Jalneti from './components/Programs/JalNeti/Jalneti';
import Pregnancy from './components/Programs/Pregnancy/Pregnancy';
import Shanmukhi from './components/Programs/Shanmukhi/Shanmukhi';
import Suryakriya from './components/Programs/SuryaKriya/Suryakriya';
import Suryashakti from './components/Programs/SuryaShakti/Surayshakti';
import Yogasanas from './components/Programs/Yogasanas/Yogasanas';
import StandardForm from './components/trial-form/standardForm';
import PregnancyForm from './components/trial-form/pregnancyForm';
import Offerings from './components/Offerings/Offerings';
import UpOff from './components/UpOff/UpOff';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import NavbarH from './components/Navbar/Navbar';
import ShanmukhiForm from './components/trial-form/shanmukhiForm';
import BhutasuddhiForm from './components/trial-form/bhutasuddhiForm';
import Dashboard from './components/Dashboard/Dashboard';
// import Registration from './components/trial-form/Registration';

function App() {
  return (
    <Router>
      <NavbarH />
      <section>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/offerings" component={<Offerings />} />
          <Route path="/up-offerings" element={<UpOff />} />
          <Route path="/about" component={<About />} />
          <Route path="/contact" component={<Contact />} />
          <Route path="/angamardana" element={<Angamardana />} />
          <Route path="/bhuta-shuddhi" element={<Bhutshuddhi />} />
          <Route path="/jal-neti" element={<Jalneti /> } />
          <Route path="/pregnancy-practices" element={<Pregnancy /> } />
          <Route path="/shanmukhi-mudra" element={<Shanmukhi /> } />
          <Route path="/surya-kriya" element={<Suryakriya /> } />
          <Route path="/surya-shakti" element={<Suryashakti />} />
          <Route path="/yogasanas" element={<Yogasanas />} />
          <Route path="/:programName/registration" element={<StandardForm /> } />
          <Route path="/bhuta-shuddhi/registration" element={<BhutasuddhiForm />} />
          <Route path="/:programName/registration/a" element={<ShanmukhiForm />} />
          <Route path="/pregnancy-practices-form" element={<PregnancyForm /> } />
          <Route path="/admin-7768" element={<Dashboard />} />
        </Routes>
      </section>
      <footer>
        <Footer />
      </footer>
    </Router>
  );
}

export default App;
